import * as React from 'react';

import { Link } from 'gatsby';

import Layout from '../components/layout.js';

export default function PrivacyPage() {
  return (
    <Layout
      title="Privacy Policy"
      description="This Privacy Policy governs all personal information collected by and provided to us."
    >
      <h1>Privacy Policy</h1>
      <hr />
      <p>
        This privacy policy has been published to better serve those who are
        concerned with how their 'Personally identifiable information' (PII) is
        being used online. PII is information that can be used on its own or
        with other information to identify, contact, or locate a single person,
        or to identify an individual in context. This Privacy Policy also,
        explains how you can contact us if you have any questions or concerns.
        By providing information to us, you agree to the terms and conditions of
        this Privacy Policy.
      </p>
      <p>We use cookies from the following third parties:</p>
      <p>
        <ul>
          <p>Google Analytics</p>
          <li>
            We use Google Analytics to collect statistical information regarding
            how the website is used. This information is not personally
            identifiable.
          </li>
          <p>Google AdSense</p>
          <li>
            We use Google AdSense to display ads on some of our pages. Google
            AdSense may use user data. You can review Google's privacy policy
            regarding advertising here. That page also provides information on
            how to manage what information is collected and how to opt out of
            collection.{' '}
          </li>
          <p>The DoubleClick Cookie</p>
          <li>
            The DoubleClick cookie is used by Google in the ads served on the
            websites of its partners, such as websites displaying AdSense ads or
            participating in Google certified ad networks. When users visit a
            partner's website and either view or click on an ad, a cookie may be
            dropped on that end user's browser.
          </li>
        </ul>
      </p>
      <p>
        Contact information:{' '}
        <p>
          If you have questions about this privacy policy, please contact me at
          admin@allcorvettemodels.com.
        </p>
      </p>
      <Link to="/">&larr; Back to home</Link>
    </Layout>
  );
}
